import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Input,
  TextField,
  MenuItem,
  IconButton,
  Menu,
} from "@mui/material";
// components
import Page from "../../../components/Page";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
// mock
// importusers ? from '../_mock/user';
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import axios from "axios";
import LinkIcon from "@mui/icons-material/Link";
import { ExportCSV } from "../../../utils/ExportCSV";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// ----------------------------------------------------------------------
// import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
const TABLE_HEAD = [
  { id: "", label: "S.No", alignRight: false },
  { id: "title", label: "subscription Title", alignRight: false },
  { id: "category", label: "Course", alignRight: false },
  { id: "sub-category", label: "Exam/Class", alignRight: false },
  { id: "accessToContent", label: "Access To Content", alignRight: false },
  { id: "courses", label: "Courses", alignRight: false },
  { id: "testseries", label: "Testseries", alignRight: false },


  { id: "amount/discount/validity", label: "Amount / Discount / Validity", alignRight: false },
  { id: "", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function SubscriptionTable() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");
  const [selectedId, setSelectedId] = useState();

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(true);
  const [deleteConfirm, setDeleteConfirm] = useState(null);


  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-subscription`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      console.log(responseJson?.data);
      usersSet(responseJson.data);
      setLoading(false);
      // if (responseJson.msg === 'not an admin') {
      //     localStorage.removeItem("isLoggedIn")
      //     localStorage.removeItem("details")
      //     navigate('/')
      // };
    }

    fetchUsers();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users?.map((n) => n.title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;
  const [isEdit, setIsEdit] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});
  //console.log(updatedValues)

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };
  const [open3, setOpen3] = useState(false);
  



  const handleClose3 = () => {
    setDeleteConfirm(null);
    setOpen3(false);
  };

  const handleConfirm3 = () => {
    handleDelete();
    handleClose3();
  };

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-subscription/${selectedRow?.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        toast.success("Subscription deleted successfully.", {
          position: "bottom-center",
        });


        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error("Failed to delete subscription. Please try again.", {
          position: "bottom-center",
        });
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.", {
        position: "bottom-center",
      });
      console.error(
        "An error occurred while deleting the subscription:",
        error
      );
    }
    handleMenuClose();
  };



  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "60vh" }}
        >
          {" "}
          <SyncLoader color="#8080ff" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Subscriptions">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Card>
              <Stack
                pr={3}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />

                {/*  <ExportCSV csvData={filteredUsers} fileName="Youtube Data" /> */}
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 1500 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row, index) => {
                          const {
                            id,
                            title,
                            amount,
                            validity,
                            // start_date,
                            // end_date,
                            discount,
                          } = row;
                          const isItemSelected = selected.indexOf(title) !== -1;
                          {
                            /* //console.log('activ statues' + is_active) */
                          }
                          const onChange = (e, row, id) => {
                            const { name, value } = e.target;

                            setUpdatedValues((prev) => {
                              return { ...prev, [name]: value };
                            });
                          };
                          const handleUpdate = async () => {
                            console.log(updatedValues);

                            setLoading(true);
                            const token = localStorage.getItem("token");
                            const authToken = token;

                            const config = {
                              headers: {
                                "content-type": "application/json",
                                Authorization: `Bearer ${authToken}`,
                              },
                            };
                            const res = await axios.put(
                              `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-subscription/${id}`,
                              updatedValues,
                              config
                            );
                            setLoading(false);
                            toast.success("Updated");
                            setTimeout(() => window.location.reload(), 800);
                          };
                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              {/* <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, title)} />
                                                    </TableCell> */}
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {index + 1 + rowsPerPage * page}
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                                  <Typography variant="subtitle2" noWrap>
                                    {isEdit && selectedId === id ? (
                                      <Input
                                        onChange={(e) => onChange(e, row, id)}
                                        // value={value}
                                        name="title"
                                        placeholder={title}
                                      />
                                    ) : (
                                      <span>{title}</span>
                                    )}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                                  <Typography variant="subtitle2" noWrap>
                                    {row?.course}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                                  <Typography variant="subtitle2" nowrap>
                                    {row?.exam_name || row?.classes_name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                pl={2}
                                width={"200px"}
                              >
                                <Stack
                                  direction="column"
                                  alignItems="flex-start"
                                  spacing={2}
                                >
                                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                                  {row?.plan[0]?.accessToContent?.map(
                                    (item) => (
                                      <Typography variant="subtitle2" nowrap>
                                        {item}
                                      </Typography>
                                    )
                                  )}
                                  {/* <Typography variant="subtitle2" nowrap>
                                    {row?.accessToContent.join(",")}
                                  </Typography> */}
                                </Stack>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                pl={2}
                                width={"250px"}
                              >
                                <Stack
                                  direction="column"
                                  alignItems="flex-start"
                                  spacing={2}
                                >
                                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                                  {row?.plan?.[0]?.courses &&
                                    row?.plan?.[0]?.courses.length > 0 ? (
                                    row.plan[0].courses.map((item, index) => (
                                      <Typography
                                        key={index}
                                        variant="subtitle2"
                                        noWrap
                                      >
                                        {item.name}
                                      </Typography>
                                    ))
                                  ) : (
                                    <Typography variant="subtitle2" noWrap>
                                      NA
                                    </Typography>
                                  )}
                                  {/* <Typography variant="subtitle2" nowrap>
                                    {row?.courses?.map(
                                      (item) => item.name + ", "
                                    )}
                                  </Typography> */}
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                                  <Typography variant="subtitle2" noWrap>
                                    {row?.plan?.[0]?.testseries &&
                                      row.plan[0].testseries.length > 0
                                      ? row.plan[0].testseries
                                        .map((item) => item.name)
                                        .join(", ")
                                      : "NA"}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="column"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                                  {row?.plan?.map((item) => (
                                    <Typography variant="subtitle2" nowrap>
                                      {item.amount + " / " + item.discounted_amount+" / "+item.validity}
                                    </Typography>
                                  ))}
                                </Stack>
                              </TableCell>
                            
                              {isEdit && selectedId === id ? (
                                <TableCell align="left">
                                  <Button
                                    variant="outlined"
                                    onClick={handleUpdate}
                                  >
                                    Update
                                  </Button>
                                </TableCell>
                              ) : null}

                              <TableCell onClick={() => setSelectedId(id)}>
                                {/* <UserMoreMenu
                                  id={id}
                                  // req="delete-subscription"
                                  {...(row.purchased === false && {
                                    req: "delete-subscription",
                                  })}
                                  isEdit={isEdit}
                                  setIsEdit={setIsEdit}
                                  setLoading={setLoading}
                                /> */}
                                <IconButton
                                  onClick={(event) => {
                                    setAnchorEl(event.currentTarget);
                                    setSelectedRow(row);
                                  }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                <Menu
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={handleClose}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      navigate(
                                        `/dashboard/updateSubscription/${selectedRow?.id}`
                                      );

                                      handleMenuClose();
                                    }}
                                  >
                                    Edit
                                  </MenuItem>
                                  {selectedRow?.purchased === false && (
                                      <MenuItem onClick={() => {
                                        setOpen3(index)
                                        setSelectedRow(selectedRow)
                                      }}>Delete</MenuItem>
                                  )}
                                </Menu>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
          <Dialog
            open={open3}
            onClose={handleClose3}
          >
            <DialogTitle>{"Confirm Deletion"}</DialogTitle>
            <DialogContent>
              Are you sure you want to Delete the Subscription ?
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleConfirm3()} color="error">
                YES
              </Button>
              <Button onClick={handleClose3} color="primary">
                NO
              </Button>
            </DialogActions>
          </Dialog>
        </Page>
      )}
    </>
  );
}
