import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
  Card,
  Select,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import "./general.css";
import MultiSelectComponent from './MultiSelectComponent';
import { getFileUrl } from '../../../utils/firebaseUtils';
import CategoryComponent from "../../../utils/CategoryComponent";


// ----------------------------------------------------------------------

export default function AddBanner() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [restrict, setRestrict] = useState("");
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);
  const [idList, setIdList] = useState();
  const [id, setId] = useState("");
  const [linkType, setLinkType] = useState("custom");
  const [bannerUrl, setBannerUrl] = useState();
  // const [customLink, setCustomLink] = useState()

  //* CATEGORY IMPLEMENTATION useStates *
  const [selectedCategory, setSelectedCategory] = useState("");
  const [classVal, setClass] = useState(null);
  const [centralExam, setCentralExam] = useState(null);
  const [stateExam, setStateExam] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [isGlobal, setIsGlobal] = useState(false); // State for the "Global" checkbox
  const [showLink, setShowLink] = useState(false);
  const [categoryComponents, setCategoryComponents] = useState([
    {
      selectedCategory: null,
      classVal: null,
      centralExam: null,
      stateExam: null,
      selectedState: null,
      selectedBoard: null,
    },
  ]);
  const removeCategoryComponent = (index) => {
    setCategoryComponents(categoryComponents.filter((_, i) => i !== index));
  };

  // Function to handle updating the component state
  const updateComponent = (index, key, value) => {
    const updatedComponents = [...categoryComponents];
    updatedComponents[index][key] = value;
    setCategoryComponents(updatedComponents);
  };
  const addCategoryComponent = () => {
    setCategoryComponents([
      ...categoryComponents,
      {
        selectedCategory: null,
        classVal: null,
        centralExam: null,
        stateExam: null,
        selectedState: null,
        selectedBoard: null,
      },
    ]);
  };
  console.log("Categories ",categoryComponents)
  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title required"),
    BannerType: Yup.string().required("Banner Type required"),



    is_active: Yup.boolean().required("Status Required"),
  });

  const defaultValues = {
    title: "",
    BannerType: "APP",
    // category: '',
    // banner_url: '',
    // language: '',
    is_active: "",
  };
  const [file, setFile] = useState();


  const handleChange = (value) => {
    //console.log(`${value}`);
  };
  const changeHandler = async (event) => {
    setFile(event.target?.files);
    const url = await getFileUrl(event.target?.files[0]);
    setBannerUrl(url);
    var fileName = document.getElementById("file").value.toLowerCase();
    if (fileName.endsWith(".pdf") || fileName.endsWith(".jpg")) {
      setRestrict("");
    } else {
      setRestrict("JPG Or PDF");
    }
    //console.log(file)
  };
  //console.log(file)
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();
  // for progress bar

  const onSubmit = async (data) => {
    if (file) {
      setLoading(true);

      // Create a JSON object with the necessary fields
      const payload = {
        title: data?.title,
        category: "Banner",
        BannerType: "APP",
        language: "en",
        is_active: data?.is_active.toString(),
        banner_url: bannerUrl,
        linkType: linkType,
        goals:categoryComponents,
        link: linkType === "custom" ? data?.customLink : id,
        course: selectedCategory,
        state_exam_id: selectedState,
        board_id: selectedBoard,
        exam_id: centralExam || stateExam,
        class_id: classVal,
        access: isGlobal ? 1 : 0,
      };

      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/create-banner`,
          payload,
          config
        );

        if (response?.data?.response_code === 200) {
          toast.success(response?.data?.message);
          setLoading(false);
          setPdf(false);
          reset();
          window.location.href = "/dashboard/banner";
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      setRestrict("Please Select pdf or jpg");
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <Typography variant="h3">Add Banner</Typography>

        {/* {console.log(idList)} */}
        <Card
          style={{ padding: "1rem 1.25rem", width: "80%" }}
          className="px-5 py-4 w-[80%]"
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <RHFTextField name="title" label="Title" />
              <Stack direction="row" spacing={3}>
                <RHFTextField
                  name="file"
                  onChange={changeHandler}
                  id="file"
                  label="File"
                  error={!file && val}
                  helperText="Image Only"
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  // inputProps={{ multiple: true }}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  onClick={() => setPdf(true)}
                  inputProps={{ accept: "image/*" }}
                  type={pdf || focus ? "file" : "text"}
                />

              </Stack>

              <Stack direction="row" spacing={3}>
                <Controller
                  name="global"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={isGlobal}
                          onChange={(e) => setIsGlobal(e.target.checked)}
                        />
                      }
                      label="All Courses"
                    />
                  )}
                />
              </Stack>

              <div>
                {!isGlobal && (
                  <>
                    {categoryComponents.map((component, index) => (<>
                      <div key={index} style={{display:"flex",alignItems:"center"}}>
                        <CategoryComponent
                        width={"100%"}
                          selectedCategory={component.selectedCategory}
                          setSelectedCategory={(value) => updateComponent(index, 'selectedCategory', value)}
                          classVal={component.classVal}
                          setClass={(value) => updateComponent(index, 'classVal', value)}
                          centralExam={component.centralExam}
                          setCentralExam={(value) => updateComponent(index, 'centralExam', value)}
                          stateExam={component.stateExam}
                          setStateExam={(value) => updateComponent(index, 'stateExam', value)}
                          selectedState={component.selectedState}
                          setSelectedState={(value) => updateComponent(index, 'selectedState', value)}
                          selectedBoard={component.selectedBoard}
                          setSelectedBoard={(value) => updateComponent(index, 'selectedBoard', value)}
                        />

                        <Button sx={{ margin: 2 }} variant="contained"
                          color="secondary"
                          onClick={() => removeCategoryComponent(index)}>Remove</Button>
                      </div>

                    </>
                    ))}
                    <Button sx={{ margin: 2 }} variant="contained"
                      color="primary"
                      onClick={addCategoryComponent}>Add</Button>
                  </>
                )}
              </div>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={showLink}
                    onChange={(e) => setShowLink(e.target.checked)}
                  />
                }
                label="Link"
              />

              {showLink && (
                <Stack direction="row" spacing={3}>
                  <Select
                    value={linkType}
                    onChange={(e) => setLinkType(e.target.value)}
                    style={{ width: "50%" }}
                  >
                    <MenuItem value="custom">Custom</MenuItem>
                    <MenuItem value="get-select-course">Courses</MenuItem>
                    <MenuItem value="getTestSeriesdetails">
                      Test Series
                    </MenuItem>
                  </Select>

                  {linkType === "custom" ? (
                    <RHFTextField
                      name="customLink"
                      label="Custom Link"
                      required
                      style={{ width: "50%" }}
                    />
                  ) : (
                    <Select
                      value={id}
                      onChange={(e) => setId(e.target.value)}
                      style={{ width: "50%" }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {/* {console.log("select k andar", idList)} */}
                      {idList?.map((obj) => {
                        return (
                          <MenuItem key={obj?._id} value={obj._id}>
                            {obj.batch_name || obj.testseries_name}
                          </MenuItem>
                        );
                      })}
                      {/* <MenuItem value="custom">Custom</MenuItem>
                  <MenuItem value="get-select-course">Courses</MenuItem>
                  <MenuItem value="getTestSeriesdetails">Test Series</MenuItem> */}
                    </Select>
                  )}
                </Stack>
              )}

              <Stack direction="row" spacing={3}>
                {/* <RHFTextField name="category" label="Category" select>
                            {users?.map((item, i) => { return <MenuItem key={i} value={item.title}>{item.title}</MenuItem> })}
                        </RHFTextField> */}

                <RHFTextField
                  name="is_active"
                  label="Status"
                  type="checkbox"
                  select
                  style={{ width: "48.5%" }}
                  className="w-1/2"
                >
                  <MenuItem value={true}>Publish</MenuItem>
                  <MenuItem value={false}>Draft</MenuItem>
                </RHFTextField>
                {/* <Select
          value={id}
          onChange={(e)=>setId(e.target.value)}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>

          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select> */}
                {/* <MultiSelectComponent setIdList={setIdList}/> */}
              </Stack>

              {/* <div className="mainDiv">
                        <div className="childDiv" style={{ width: `${progress}%` }}>
                            <span> </span>
                        </div>
                    </div> */}

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={loading}
                sx={{
                  bgcolor: "#e6e6ff",
                  color: "black",
                  marginTop: "5%",
                  "&:hover": { background: " #8080ff" },
                }}
                onClick={() => {
                  setVal(true);
                  setMsg("Required");
                }}
              >
                Add
              </LoadingButton>
            </Stack>
          </FormProvider>
        </Card>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
