import { filter } from "lodash";
// import React from 'react';
import React, { useEffect, useState } from "react";
// import { useEffect, useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  MenuItem,
} from "@mui/material";
// components
import Page from "../components/Page";

import Scrollbar from "../components/Scrollbar";

import SearchNotFound from "../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../sections/@dashboard/user";
// mock
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import { ExportCSV } from "../utils/ExportCSV";
import { ToastContainer, toast } from "react-toastify";

// for Dialog box
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios"; // for axios request

import { CSVLink } from "react-csv"; // for CSV file download
import { RHFTextField } from "../components/hook-form";

import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ro } from "date-fns/locale";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "S.No", alignRight: false },
  { id: "enrolid", label: "Enrollment No.", alignRight: false },
  { id: "StudentName", label: "Name", alignRight: false },
  { id: "mobileNumber", label: "Phone No.", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "orderId", label: "Order ID", alignRight: false },
  { id: "purchaseAt", label: "Purchased At", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "paid", label: "Paid", alignRight: false },
  { id: "couponCode", label: "Coupon Code", alignRight: false },
  { id: "Status", label: "Status", alignRight: false },
  { id: "Action", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(array, (_user) => {
      const studentNameMatch =
        _user?.user?.name &&
        _user?.user?.name.toLowerCase().includes(query.toLowerCase());
      const emailMatch =
        _user?.user?.email &&
        _user?.user?.email.toLowerCase().includes(query.toLowerCase());
      const phoneMatch =
        _user?.mobileNumber &&
        _user.mobileNumber.toLowerCase().includes(query.toLowerCase());
      // const idMatch =
      //   _user?.id &&
      //   _user.id.toLowerCase().includes(query);
      // const examName =
      //   _user?.exam_name &&
      //   _user.exam_name.toLowerCase().includes(query.toLowerCase());
      return studentNameMatch || emailMatch || phoneMatch;
    });
  }

  return stabilizedThis?.map((el) => el[0]);
}

export default function Students() {
  //date-time
  const [starting, setStarting] = React.useState(dayjs().subtract(24, 'hour'));
  const [ending, setEnding] = React.useState(dayjs());

  const handleChange = (newValue) => {
    setStarting(newValue);
  };
  const handleChangeTwo = (newValue) => {
    setEnding(newValue);
  };

  // Code for handling Dialog box
  const [showDownload, setShowDownload] = useState(false);
  const [download, setDownload] = useState(true);
  const [statusUser, setStatusUser] = useState();
  const [statusStaus, setStatusStatus] = useState();
  const [errorOTP, setErrorOtp] = useState();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendOtp = () => {
    const token = localStorage.getItem("token");
    const authToken = token;
  };
  const verifyOtp = () => {
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      otp: document.getElementById("name").value,
    };
  };

  const [page, setPage] = useState(0);
  const [apiPage, setApiPage] = useState(1);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(true);

  //Getting Details
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-user-list?page=${apiPage}&limit=${500}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson?.data);
      // console.log("new userlist api response ",responseJson?.data);
      setLoading(false);
      if (responseJson?.msg === "not an admin") {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("details");
        navigate("/");
      }
    }

    fetchUsers();
    handleFilter("batch", "all");

    return () => {

    }
  }, [apiPage]);

  const [type, setType] = useState("batch");
  const [valueId, setValueId] = useState('all');
  const [list, setList] = useState();

  const [filterSearch, setFilterSearch] = useState([]);
  const handleFilter = async (selectedType, valueIdType) => {
    // setLoading(true)
    let token = localStorage.getItem("token");
    const data = {
      type: selectedType ? type : selectedType,
      valueId: "batch",
      // startAt: selectedType ? starting.$d : "2023-10-05T08:59:55.000Z",
      // endAt: selectedType? ending.$d : "2023-10-11T08:59:55.000Z",
      startAt: starting.$d,
      endAt: ending.$d

    };
    try {
    
    } catch (error) {
    }
    // navigate('/dashboard', { replace: true });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target?.checked) {
      const newSelecteds = users?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target?.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target?.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    filterSearch ? filterSearch : ["no"],
    getComparator(order, orderBy),
    filterName
  );

  const csvData = filteredUsers?.map((item) => {
    let purchaseItemId = `${type}Id`;
    return {
      // (type == 'batch' ? batchId : testSeriesId) 
      [purchaseItemId]: item?.id,
      is_active: item?.is_active,
      purchaseAt: item?.purchaseAt,
      amount: item?.amount,
      is_paid: item?.is_paid,
      txnId: item?.txnId,
      userId: item?.user?.userId,
      userName: item?.user?.name,
      userEmail: item?.user?.email,
      userPhone: item?.user?.phone,
      userCategory: item?.user?.catgeory,
      value: item?.value,
    }
  })
  const isUserNotFound = filteredUsers?.length === 0;
  let i = 0;
  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader
            color=" #8080ff"
            loading={loading}
            size={20}
          />
        </Stack>
      ) : (
        <Page title="Students">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <Typography variant="h3" gutterBottom>
                All Purchase Report
              </Typography>
            </Stack>

            <Card
              sx={{
                width: "100%",
                position: "relative",
                padding: "40px",
                height: "100%",
                marginBottom: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "40px",
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  top: "10px",
                  left: "20px",
                  fontWeight: "bold",
                }}
              >
                Filters
              </Typography>

              <TextField
                name="type"
                label="Type"
                select
                defaultValue="batch"
                sx={{ height: "50px", width: "150px" }}
              >
                {/* <MenuItem value="all" onClick={()=>setType('all')}>All</MenuItem> */}
                <MenuItem value="batch" onClick={() => setType("batch")}>
                  Batch
                </MenuItem>
                <MenuItem
                  value="testseries"
                  onClick={() => setType("testseries")}
                >
                  Test Series
                </MenuItem>
              </TextField>
              <TextField
                name="valueId"
                label={type == "batch" ? "All Courses" : "All Test Series"}
                select
                defaultValue="all"
                sx={{ height: "50px", width: "150px" }}
              >
                <MenuItem value="all" onClick={() => setType("all")}>
                  All
                </MenuItem>
                {[
                  list?.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={item?._id}
                        onClick={(e) => setValueId(item?._id)}
                      >
                        {type == "batch"
                          ? item?.batch_name
                          : item?.testseries_name}
                      </MenuItem>
                    );
                  }),
                ]}
              </TextField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack
                  direction="row"
                  spacing={3}
                  sx={{ justifyContent: "space-between" }}
                >
                  <DateTimePicker
                    label="From"
                    value={starting.$d}
                    onChange={handleChange}
                    // name="starting_date"
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%" }} />
                    )}
                  />
                  <DateTimePicker
                    label="To"
                    value={ending.$d}
                    onChange={handleChangeTwo}
                    sx={{ color: "black" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%", color: "black" }}
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>

              <Button variant="contained" className="text-blue-500 hover:text-white" onClick={() => handleFilter(type, valueId)}>
                Search
              </Button>
            </Card>

            <Card>
              <Stack
                pr={3}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />

               
                <Dialog open={open} onClose={handleClose}>
                  {download ? (
                    <>
                      <DialogTitle>OTP Varification</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          OTP is send successfully to your regisered e-mail.
                        </DialogContentText>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="OTP Number"
                          type="number"
                          fullWidth
                          variant="standard"
                        />
                        <DialogContentText
                          sx={{
                            color: "red",
                            fontSize: "12px",
                            padding: "3px",
                          }}
                        >
                          {errorOTP}
                        </DialogContentText>
                      </DialogContent>

                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={verifyOtp}>Verify</Button>
                      </DialogActions>
                    </>
                  ) : (
                    <>
                      <DialogTitle>Download File</DialogTitle>
                      <DialogContent>
                        <CSVLink data={csvData} filename="AllUsers.csv" onClick={() => handleClose()}>
                          Download
                        </CSVLink>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            handleClose();
                            setDownload(true);
                          }}
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </>
                  )}
                </Dialog>
              </Stack>

              <Scrollbar>
                <TableContainer className="text-nowrap" sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={filteredUsers.length} // Use filteredUsers length for accurate count
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                      noWrap
                    />
                    <TableBody>
                      {filteredUsers && filteredUsers.length > 0 ? (
                        filteredUsers
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const { id, purchaseAt, is_active, is_paid, txnId, amount, couponCode } = row;
                            const { userId, enrollId, icon, name, phone, email } = row.user;
                            const isItemSelected = selected.indexOf(name) !== -1;

                            return (
                              <TableRow
                                hover
                                key={id} // Use id as key for better performance
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell>
                                  <Typography variant="subtitle2" noWrap>
                                    {index + 1 + rowsPerPage * page}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="subtitle2" noWrap>
                                    {enrollId}
                                  </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" pl={2}>
                                  <Stack direction="row" alignItems="center" spacing={2}>
                                    <Avatar alt={name} src={icon} sx={{ width: 28, height: 28 }} />
                                    <Typography variant="subtitle2" noWrap>
                                      {name}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">{phone || "NA"}</TableCell>
                                <TableCell align="left">{email}</TableCell>
                                <TableCell align="left">{txnId || "NA"}</TableCell>
                                <TableCell align="left">{purchaseAt.split(" ")[0]}</TableCell>
                                <TableCell align="left">{amount || "NA"}</TableCell>
                                <TableCell align="left">
                                  {is_paid ? (
                                    <span style={{ color: "green" }}>Paid</span>
                                  ) : (
                                    <span style={{ color: "red" }}>Not paid</span>
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  <div className={`${couponCode ? "bg-green-400 text-white px-2 py-1 rounded-md" : "text-red-500"} flex text-center justify-center`}>
                                    {couponCode || "Not Applied"}
                                  </div>
                                </TableCell>
                                <TableCell align="left">
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color={is_active ? "success" : "error"}
                                    onClick={() => {
                                      handleClickOpen();
                                      sendOtp();
                                      setStatusStatus(is_active);
                                      setStatusUser(id);
                                    }}
                                  >
                                    {is_active ? "Active" : "InActive"}
                                  </Button>
                                </TableCell>
                                <TableCell align="left">
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="error"
                                    onClick={() => navigate(`/dashboard/students/studentsProfile/${userId}`)}
                                  >
                                    View
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>

                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredUsers?.length}
                // count={filterSearch?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Page>
      )}
    </>
  );
}
