import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Input,
  MenuItem,
  TextField,
  Box,
  Tabs,
  Tab,
  IconButton,
  Menu,
} from "@mui/material";
// components
import Page from "../../../components/Page";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
// mock
// importusers ? from '../_mock/user';
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
//mui icons
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import { ExportCSV } from "../../../utils/ExportCSV";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// For Dialog Box
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { CSVLink } from "react-csv"; // for CSV file download
import AddCoupon from "./AddCoupon";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "sno", label: "S.No", alignRight: false },
  { id: "name", label: " Name", alignRight: false },
  { id: "couponCode", label: " Code", alignRight: false },
  { id: "couponPercentage", label: " Percentage", alignRight: false },
  { id: "course", label: "Course", alignRight: false },

  { id: "exam name", label: "Exam  / Class", alignRight: false },
  { id: "startingDate", label: "Starting Date", alignRight: false },
  { id: "expireDate", label: "Expire Date", alignRight: false },
  { id: "access", label: "Access", alignRight: false },
  { id: "coupon_type", label: "Coupon Type", alignRight: false },
  { id: "expireDate", label: "Applied", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];



// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(b, a, orderBy)
    : (a, b) => -descendingComparator(b, a, orderBy);
}


const applySortFilter = (array, comparator, query) => {
  const stabilizedThis = array?.map((el, index) => [el, index]);

 
  if (query) {
    return filter(array, (item) => {
      const name = item?.name?.toLowerCase() || "";
      const couponCode = item?.couponCode?.toLowerCase() || "";
      return (
        name.indexOf(query.toLowerCase()) !== -1 ||
        couponCode.indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  const value=stabilizedThis?.map((el) => el[0]);
  // usersSet(value)
  return value ;
};

// Example comparator function
const comparator = (a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};







export default function AllCoupon() {
  // Code for handling Dialog box
  const [download, setDownload] = useState(true);
  const [errorOTP, setErrorOtp] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [coupons, setCoupons] = useState([]);


  // Handle search query change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Get filtered coupons
  const filteredCoupons = applySortFilter(coupons, comparator, searchQuery);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };


  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState();
  const [starting, setStarting] = useState(dayjs());
  const handleChange = (newValue) => {
    setStarting(newValue);
  };

  //Getting notes
  const [isActiveValue, setIsActiveValue] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-coupons`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users?.map((n) => n.title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  // console.log("Coupon ",users)
  //   const filtered = users.filter(item => 
  //     (item.name).toLowerCase().includes(event.target.value)|| (item.coupon_code).toLowerCase().includes(event.target.value)
  //   );
    
  //   usersSet(filtered);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );
  // usersSet(filteredUsers)




  const isUserNotFound = filteredUsers.length === 0;
  const [isEdit, setIsEdit] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});

  //Enable Coupon
  const enableCoupon = async (id, val) => {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await axios.put(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-coupon-status/${id}`,
      {
        status: val,
      },
      config
    );

    setLoading(false);
    toast.success("Updated");
    setTimeout(() => window.location.reload(), 800);
  };

  const [value, setValue] = useState("coupon");

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    console.log("new tab value", newValue);
  };

  const access = JSON.parse(localStorage.getItem("detailObject"));

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);



  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-coupon/${selectedRow?.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        toast.success("coupon deleted successfully.", {
          position: "bottom-center",
        });

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error("Failed to delete coupon. Please try again.", {
          position: "bottom-center",
        });
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.", {
        position: "bottom-center",
      });
      console.error("An error occurred while deleting the coupon:", error);
    }
    handleMenuClose();
  };
  const [open3, setOpen3] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(null);


  const handleClose3 = () => {
    setDeleteConfirm(null);
    setOpen3(false);
  };

  const handleConfirm3 = () => {
    handleDelete();
    handleClose3();
  };

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "60vh" }}
        >
          {" "}
          <SyncLoader color="#8080ff" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Coupons">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <div
              style={{ width: "100%", marginBottom: "2rem" }}
              className="w-full mb-8"
            >
              <Box sx={{ width: "100%", bgcolor: "background.paper", pl: 2 }}>
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons={false}
                >
                  <Tab
                    label="All Coupons"
                    style={{ width: "100%" }}
                    value="coupon"
                  />
                  <Tab
                    label="Add Coupon"
                    style={{ width: "100%" }}
                    value="add"
                  />
                </Tabs>
              </Box>
            </div>

            {value === "coupon" ? (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={3}
                >
                  <Typography variant="h3" gutterBottom>
                    All Coupons
                  </Typography>
                </Stack>

                <Card>
                  <Stack
                    pr={3}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <UserListToolbar
                      numSelected={selected.length}
                      filterName={filterName}
                     
                      onFilterName={handleFilterByName}
                    />


                  </Stack>

                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                      <Table>
                        <UserListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={users?.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                          {filteredUsers
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            ?.map((row, index) => {
                              const {
                                name,
                                coupon_code,
                                coupon_percentage,
                                course,
                                exam_name,
                                classes_name,
                                start_from,
                                end_to,
                                applied,
                                access,
                                coupon_type,
                                status,
                                id,
                              } = row;
                              const isItemSelected =
                                selected.indexOf(coupon_code) !== -1;



                              const onChange = (e, row, id) => {
                                const { name, value } = e.target;
                                if (name === "status") {
                                  setIsActiveValue(true);
                                }
                                setUpdatedValues((prev) => ({
                                  ...prev,
                                  [name]: value,
                                }));
                              };




                              return (
                                <TableRow
                                  hover
                                  key={index}
                                  tabIndex={-1}
                                  role="checkbox"
                                  selected={isItemSelected}
                                  aria-checked={isItemSelected}
                                >
                                  <TableCell>
                                    <Typography variant="subtitle2">
                                      {index + 1 + rowsPerPage * page}
                                    </Typography>
                                  </TableCell>
                                  <TableCell component="th" scope="row" pl={2}>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Typography variant="subtitle2" noWrap>
                                        {isEdit && selectedId === id ? (
                                          <Input
                                            onChange={(e) =>
                                              onChange(e, row, id)
                                            }
                                            name="name"
                                            placeholder={name}
                                          />
                                        ) : (
                                          <span>{name}</span>
                                        )}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell component="th" scope="row" pl={2}>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Typography variant="subtitle2" noWrap>
                                        {isEdit && selectedId === id ? (
                                          <Input
                                            onChange={(e) =>
                                              onChange(e, row, id)
                                            }
                                            name="coupon_code"
                                            placeholder={coupon_code}
                                          />
                                        ) : (
                                          <span>{coupon_code}</span>
                                        )}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell component="th" scope="row" pl={2}>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Typography variant="subtitle2" noWrap>
                                        {isEdit && selectedId === id ? (
                                          <Input
                                            onChange={(e) =>
                                              onChange(e, row, id)
                                            }
                                            name="coupon_percentage"
                                            placeholder={coupon_percentage}
                                          />
                                        ) : (
                                          <span>{coupon_percentage}</span>
                                        )}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell align="center">
                                    <span>{course}</span>
                                  </TableCell>

                                  <TableCell align="center">
                                    <span>
                                      {exam_name || classes_name || "NA"}
                                    </span>
                                  </TableCell>
                                  <TableCell component="th" scope="row" pl={2}>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Typography variant="subtitle2" noWrap>
                                        <span>{start_from?.slice(0, 10)}</span>
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell component="th" scope="row" pl={2}>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Typography variant="subtitle2" noWrap>
                                        <span>{end_to?.slice(0, 10)}</span>
                                      </Typography>

                                    </Stack>
                                  </TableCell>
                                  <TableCell component="th" scope="row" pl={2}>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Typography variant="subtitle2" noWrap>
                                        <span>{access == 1 ? "Multiple Time" : "One TIme"}</span>

                                      </Typography>

                                    </Stack>
                                  </TableCell>
                                  <TableCell component="th" scope="row" pl={2}>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Typography variant="subtitle2" noWrap>
                                        <span>{coupon_type == 1 ? "Public" : "Private"}</span>
                                      </Typography>

                                    </Stack>
                                  </TableCell>
                                  <TableCell component="th" scope="row" pl={2}>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Typography variant="subtitle2" noWrap>
                                        <span>{applied}</span>
                                      </Typography>

                                    </Stack>
                                  </TableCell>

                                  <TableCell align="left">
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      color={status ? "error" : "success"}
                                      onClick={() => enableCoupon(id, !status)}
                                    >
                                      {status === 1 ? "Disable" : "Enable"}
                                    </Button>
                                  </TableCell>

                                  <TableCell>
                                    <IconButton
                                      onClick={(event) => {
                                        setAnchorEl(event.currentTarget);
                                        setSelectedRow(row);
                                      }}
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                      anchorEl={anchorEl}
                                      open={Boolean(anchorEl)}
                                      onClose={handleMenuClose}
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          navigate(
                                            `/dashboard/updatecoupon/${selectedRow?.id}`
                                          );
                                          handleMenuClose();
                                        }}
                                      >
                                        Edit
                                      </MenuItem>

                                      <MenuItem onClick={() => {
                                        setOpen3(true)
                                        console.log("Index ",index,open3)
                                        setSelectedRow(selectedRow)
                                      }
                                      }>
                                        Delete
                                      </MenuItem>
                                    </Menu>
                                  </TableCell>
                                </TableRow>
                              );
                            })}

                        </TableBody>

                        {isUserNotFound && (
                          <TableBody>
                            <TableRow>
                              <TableCell
                                align="center"
                                colSpan={6}
                                sx={{ py: 3 }}
                              >
                                <SearchNotFound searchQuery={filterName} />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Scrollbar>
                  <ToastContainer
                    position="bottom-center"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={users?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Card>
              </>
            ) : (
              <AddCoupon />
            )}
          </Container>
          <Dialog
            open={open3}
            onClose={handleClose3}
          >
            <DialogTitle>{"Confirm Deletion"}</DialogTitle>
            <DialogContent>
              Are you sure you want to Delete the Coupon ?
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleConfirm3()} color="error">
                YES
              </Button>
              <Button onClick={handleClose3} color="primary">
                NO
              </Button>
            </DialogActions>
          </Dialog>
        </Page>
      )}
    </>
  );
}
