import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  IconButton,
  Popover,
  TablePagination,
  Skeleton,
  Checkbox,
  Button,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import Page from "../../../components/Page";
import SyncLoader from "react-spinners/SyncLoader";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Test = () => {
  const access = JSON.parse(localStorage.getItem("detailObject"));
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");

  const [filteredTestSeries, setFilteredTestSeries] = useState();
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [batchIdForPopover, setBatchIdForPopover] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [page, setPage] = useState(0); // Adjusted initial page to 0
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [resData, setResData] = useState();

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const currentPageRows = resData
        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
        .map((user) => user.id);
      setSelectedRows(currentPageRows);
      setSelectAll(true);
    } else {
      setSelectedRows([]);
      setSelectAll(false);
    }
  };
  const handleRowSelect = (userId) => {
    if (selectedRows.includes(userId)) {
      setSelectedRows(selectedRows.filter((id) => id !== userId));
    } else {
      setSelectedRows([...selectedRows, userId]);
    }
  };
  const handleDeleteButtonDisabled = () => {
    return selectedRows.length === 0;
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    async function fetchTestSeries() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-testseries`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseData = await response.json();
        console.log(responseData?.data);
        setFilteredTestSeries(responseData?.data);
        setResData(responseData?.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching test series data:", error);
        setLoading(false);
      }
    }

    fetchTestSeries();
  }, []);

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setBatchIdForPopover(id);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
    setSelectedBatch(null);
  };
  const open = Boolean(anchorEl);

  const deleteDetails = async () => {
    const token = localStorage.getItem("token");

    try {
      await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-testseries/${batchIdForPopover}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Test Series Deleted");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error deleting test series:", error);
    }
  };
  const handleSearchChange = (e) => {
    setSearchValue(e.target?.value);
    setFilteredTestSeries(
      resData?.filter((item) =>
        item?.name?.toLowerCase()?.includes(e.target?.value?.toLowerCase())
      )
    );
    setPage(0);
    if (e.target?.value === null || e.target?.value === "") {
      setFilteredTestSeries(resData);
    }
  };

  const makeActive = async (item) => {
    handleCloseActions(); // Close the popover
    setLoading(true);
    const token = localStorage.getItem("token"); // Toggle the value

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-testseries/${batchIdForPopover}`,
        {
          is_active: "true",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setLoading(false);
      // console.log(response?.data)
      if (response.data.response_code === 200) {
        toast.success(response?.data?.message);
        setTimeout(() => window.location.reload(), 1000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error updating test series:", error);
      setLoading(false);
    }
  };

  const makeInactive = async (item) => {
    setLoading(true);
    handleCloseActions(); // Close the popover

    const token = localStorage.getItem("token");

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-testseries/${batchIdForPopover}`,
        {
          is_active: "false",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setLoading(false);

      if (response.data.response_code === 200) {
        toast.success(response?.data?.message);
        setTimeout(() => window.location.reload(), 1000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error updating test series:", error);
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
  };

  const handleDeleteSelected = async () => {
    const token = localStorage.getItem("token");
    console.log([...selectedRows]);
    // const res = await axios.delete(
    //   `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-bulk-testseries`,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   },
    //   { ids: [...selectedRows] }
    // );
    // console.log(res);
    const res = await fetch(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-bulk-testseries`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ids: [...selectedRows] }),
      }
    );
    const fullres = res.json();
    toast.success("Delete successfully");
    setTimeout(() => {
      window.location.reload();
    }, 1500);

    console.log(res);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredTestSeries?.length - page * rowsPerPage);

  return (
    <Page title="Test Series">
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <SyncLoader color=" #8080ff" loading={loading} size={20} />
        </Stack>
      ) : (
        <>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            pl={5}
            pr={3}
            marginBottom={"50px"}
          >
            <Typography className="page-heading">All Test Series</Typography>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                width: "500px",
                height: "40px",
                // marginBottom: "70px",
                "& label": {
                  color: "black",
                },
                "& fieldset": {
                  borderColor: "black",
                },
              }}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              {selectedRows.length > 0 && (
                <Box height={"40px"}>
                  {/* <Typography>{selectedRows?.length}</Typography> */}
                  <Button
                    variant="contained"
                    color="error"
                    sx={{
                      height: "40px",
                    }}
                    onClick={handleDeleteSelected}
                    // disabled={handleDeleteButtonDisabled()}
                  >
                    Delete Selected Rows
                  </Button>
                </Box>
              )}

              {/* Search Bar */}
              <TextField
                label="Search Course"
                variant="outlined"
                margin="normal"
                value={searchValue}
                onChange={handleSearchChange}
                // sx={{
                //   width: "300px",
                //   height: "20px",
                //   marginBottom: "70px",
                //   "& label": {
                //     color: "black",
                //   },
                //   "& fieldset": {
                //     borderColor: "black",
                //   },
                // }}
              />
            </Stack>
          </Grid>

          {/* Display Test Series Table */}
          <TableContainer component={Paper} pl={5} pr={3} mt={2}>
            <Table>
              <TableHead
                style={{
                  backgroundColor: "#e6e6ff",
                }}
              >
                <TableRow>
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAll}
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < resData.length
                    }
                  />

                  <TableCell>Test Series Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Exam Name / Class</TableCell>
                  <TableCell>Medium</TableCell>
                  <TableCell>Number of Students</TableCell>
                  <TableCell>Amount / Discount / Validity

                  </TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? filteredTestSeries
                      ?.filter(
                        (item) =>
                          item?.name ||
                          ""?.toLowerCase()?.includes(searchValue.toLowerCase())
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                  : filteredTestSeries
                )?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRows.includes(item.id)}
                        onChange={() => handleRowSelect(item.id)}
                      />
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/dashboard/test/testSeriesDetails/${item?.id}`}
                        style={{
                          textDecoration: "none",
                          cursor: "pointer",
                          color: "inherit",
                        }}
                      >
                        {item?.name || "NA"}
                      </Link>
                    </TableCell>
                    <TableCell>{item?.course}</TableCell>
                    <TableCell>
                      {item?.exam_name || item?.classes_name || "NA"}
                    </TableCell>
                    <TableCell>{item?.language=="en"?"English":"Hindi"}</TableCell>

                    <TableCell>
                      {Array.isArray(item?.student)
                        ? item?.student.length
                        : item?.student?.split(",").length || 0}
                    </TableCell>
                    <TableCell>{item?.charges +" / "+item?.discount+" / "+item?.Validity}</TableCell>
                    <TableCell>
                      {item?.starting_date?.split(" ")[0] || "NA"}
                    </TableCell>
                    <TableCell>
                      {item?.ending_date?.split(" ")[0] || "NA"}
                    </TableCell>
                    <TableCell
                      style={{
                        color: item?.is_active === "true" ? "green" : "red",
                      }}
                    >
                      {item?.is_active === "true" ? "Published" : "Draft"}
                    </TableCell>
                    <TableCell>
                      <Box
                        onClick={() => {
                          setBatchIdForPopover(item?.id);
                        }}
                      >
                        <MoreVertIcon
                          sx={{
                            color: "",
                            background: "rgba(255, 252, 252, 0.72)",
                            borderRadius: "50%",
                            cursor: "pointer",
                          }}
                          onClick={(event) => handleClick(event, index)}
                          aria-describedby={`popover-${index}`}
                        />
                      </Box>
                      <Popover
                        id={`popover-${index}`}
                        open={open && batchIdForPopover === item?.id}
                        anchorEl={anchorEl}
                        onClose={handleCloseActions}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Typography
                          pl={4}
                          pr={4}
                          pb={1}
                          pt={0.5}
                          sx={{
                            color: "gray",
                            cursor: "pointer",
                            "&:hover": {
                              background: "#e6e6ff",
                            },
                          }}
                          onClick={() =>
                            navigate(
                              `/dashboard/test/updateTestSeries/${batchIdForPopover}`
                            )
                          }
                        >
                          Edit
                        </Typography>
                        {item?.student?.length === 0 && (
                          <Typography
                            pl={4}
                            pr={4}
                            pb={1}
                            pt={0.5}
                            sx={{
                              color: "gray",
                              cursor: "pointer",
                              "&:hover": {
                                background: "#e6e6ff",
                              },
                            }}
                            onClick={() => deleteDetails(batchIdForPopover)}
                          >
                            Delete
                          </Typography>
                        )}
                        <Typography
                          pl={4}
                          pr={4}
                          pb={1}
                          pt={0.5}
                          sx={{
                            color: "gray",
                            cursor: "pointer",
                            "&:hover": {
                              background: "#e6e6ff",
                            },
                          }}
                          onClick={() =>
                            item?.is_active == "true"
                              ? makeInactive(item)
                              : makeActive(item)
                          }
                        >
                          {item?.is_active === "true"
                            ? "Move to Draft"
                            : "Publish"}
                        </Typography>
                      </Popover>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredTestSeries?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
          {/* <Box mt={2} width={"100%"}>
            <Button
              variant="contained"
              color="error"
              width={"100%"}
              onClick={handleDeleteSelected}
              disabled={handleDeleteButtonDisabled()}
            >
              Delete Selected Rows
            </Button>
          </Box> */}
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </>
      )}
    </Page>
  );
};

export default Test;
